import ArtistDropdown from 'components/Dropdown/ArtistDropdown';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import Featuring from 'shared/ui/components/Featuring';
import LiveDescription from 'components/LiveDescription';
import LiveDropdown from 'components/Dropdown/LiveDropdown';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import safeStringify from 'utils/safeStringify';
import Section from 'components/Section';
import ShowMoreTiles from 'components/ShowMoreTiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import transport from 'api/transport';
import Truncate from 'components/Truncate';
import { Artist } from 'state/Artists/types';
import { Component, ReactNode } from 'react';
import { get } from 'lodash-es';
import { getGenreRecs } from 'state/Recs/services';
import { LiveStation } from 'state/Live/types';
import { Market } from 'state/Location/types';
import { parse as parseRecs } from 'state/Recs/shims';
import { STATION_TYPE } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

// disabled lines are a result of eslint not seeing refreshGenreRecs as accessing props
export type Props = {
  ampUrl: string;
  currentMarket: Market;
  favoriteStations: Array<Artist | LiveStation>;
  genreId: number;
  header: ReactNode;
  playedFrom: number;
  stationId: number | string;
  template: 'CR' | 'DL' | 'LRRM';
  url?: string;
};

type State = {
  stations: Array<{
    [key: string]: any;
  }>;
};

class TopStations extends Component<Props, State> {
  state = {
    stations: [],
  };

  componentDidMount(): void {
    if (!this.props.currentMarket) return;
    this.refreshGenreRecs(this.props);
  }

  componentDidUpdate(prevProps: Props): void {
    const { currentMarket } = prevProps;
    if (!this.props.currentMarket || currentMarket === this.props.currentMarket)
      return;
    this.refreshGenreRecs(this.props);
  }

  async refreshGenreRecs({
    ampUrl,
    currentMarket,
    genreId,
    playedFrom,
    stationId,
    template,
  }: Props): Promise<void> {
    let genreRecsResponse;
    const ops = {
      lat: get(currentMarket, ['loc', 'lat']).toFixed(2),
      limit: 6,
      long: get(currentMarket, ['loc', 'lon']).toFixed(2),
      template,
    };
    try {
      genreRecsResponse = await transport(
        getGenreRecs({
          ampUrl,
          genreId,
          ops,
        }),
      );
    } catch (err) {
      // WEB-10253 - 12/18/17 - AV
      // we don't throw the error here because then this endpoint going down would take the rest of the page with it.
      // eslint-disable-next-line no-console
      console.error(
        `genre request for genres ${genreId} and opts ${safeStringify(
          ops,
          null,
          2,
        )}`,
        {
          err,
        },
      );
    }

    const recs = get(genreRecsResponse, ['data', 'values'], []);

    const stations = parseRecs(recs)
      .filter(
        ({ content, basedOn }: any) =>
          !(
            content.seedType === STATION_TYPE.LIVE &&
            content.seedId === stationId
          ) || !basedOn[genreId],
      )
      .map((s: any) => ({
        ...s.content,
        imgWidth: TILE_RES,
        playedFrom,
      }));

    this.setState({ stations });
  }

  render() {
    const {
      favoriteStations,
      header,
      playedFrom,
      url: sectionUrl,
    } = this.props;
    const { stations } = this.state;

    if (!stations.length) return null;
    const STATION_LIMIT = 3;
    return (
      <Section header={header} url={sectionUrl}>
        <ShowMoreTiles
          subtitleLines={2}
          tilesData={stations.map(station => {
            const {
              seedId,
              description,
              name,
              seedType,
              url,
              logo,
              rawLogo,
              imgUrl,
            } = station;
            const isFavorite = favoriteStations.filter(
              s => get(s, 'seedId') === seedId,
            );
            const subTitleMap = {
              [STATION_TYPE.LIVE]: <LiveDescription text={description} />,
              [STATION_TYPE.ARTIST]: <Featuring artistId={seedId} />,
              undefined: <div>{description}</div>,
            };
            return {
              children: (
                <NavLink
                  css={{ display: 'block', position: 'relative' }}
                  data-test={`genre-rec-${seedType}`}
                  to={url}
                >
                  <PlayButtonContainerPrimitive>
                    <PlayButton
                      className="play"
                      deferPlay={!!url}
                      playedFrom={playedFrom}
                      stationId={seedId}
                      stationType={seedType}
                    />
                    <TilesImageWrapper
                      liveTile={seedType === STATION_TYPE.LIVE}
                    >
                      <CatalogImage
                        alt={name}
                        aspectRatio={1}
                        height={TILE_RES}
                        id={seedId}
                        src={logo || rawLogo || imgUrl}
                        type={seedType}
                        width={TILE_RES}
                      />
                    </TilesImageWrapper>
                  </PlayButtonContainerPrimitive>
                </NavLink>
              ),
              dropdown:
                seedType === STATION_TYPE.ARTIST ? (
                  <ArtistDropdown
                    artist={station}
                    followed={!!isFavorite.length}
                    key={`artist-${seedId}`}
                  />
                ) : (
                  <LiveDropdown
                    key={`station-${seedId}`}
                    name={name}
                    seedId={seedId}
                    seedType={seedType}
                  />
                ),
              key: seedId,
              subTitle: <Truncate lines={2}>{subTitleMap[seedType]}</Truncate>,
              tilesInRow: STATION_LIMIT,
              title: name,
              titleSingleLine: true,
              url,
            };
          })}
          tilesInRow={STATION_LIMIT}
          titleLines={1}
        />
      </Section>
    );
  }
}

export default TopStations;
